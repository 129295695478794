import { LoadingButton } from "@mui/lab";
import { Button, Typography } from "@mui/material";
import ModalDialog from "components/molecular/ModalDialog";
import { useSnackbar } from "notistack";
import { useState, useCallback } from "react";
import { useDeleteFixWorkflowVersionMutation } from "services/workflow";

import type { WorkflowDelVerData } from "../common/type_defs";
import type { FC } from "react";

interface DeleteWFVersionDialogProps {
  open: boolean;
  onClose: () => void;
  isLoading: boolean;
  handleDelete: () => void;
}

interface OpenDeleteWFVersionDialogProps {
  workflowType: string;
  fiscalYear: string;
  version: string;
}

const DeleteWFVersionDialog: FC<DeleteWFVersionDialogProps> = ({
  open,
  onClose,
  isLoading,
  handleDelete,
}) => (
  <ModalDialog
    open={open}
    onClose={onClose}
    title="Confirm"
    buttons={
      <>
        <Button variant="contained" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton color="error" variant="outlined" loading={isLoading} onClick={handleDelete}>
          Delete
        </LoadingButton>
      </>
    }
    content={
      <>
        <Typography>Are you sure want to delete WF Version?</Typography>
      </>
    }
    size="tiny"
  />
);

export const useDeleteWFVersionDialog = ({ refetchWorkflow }: any) => {
  const [open, setOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [workflowType, setWorkflowType] = useState<string>("");
  const [fiscalYear, setFiscalYear] = useState<string>("");
  const [version, setVersion] = useState<string>("");

  const [deleteFixWorkflowVersion, { isLoading }] = useDeleteFixWorkflowVersionMutation();

  const openDeleteWFVersionDialog = async ({
    workflowType,
    fiscalYear,
    version,
  }: OpenDeleteWFVersionDialogProps) => {
    setOpen(true);

    setWorkflowType(workflowType);
    setFiscalYear(fiscalYear);
    setVersion(version);
  };

  const closeDeleteWFVersionDialog = useCallback(() => {
    setOpen(false);

    setWorkflowType("");
    setFiscalYear("");
    setVersion("");
  }, []);

  const handleDelete = async () => {
    const data: WorkflowDelVerData = {
      workflowType: workflowType ?? "",
      fiscalYear,
      version: version ?? "",
    };
    try {
      await deleteFixWorkflowVersion(data).unwrap();
      refetchWorkflow();
      enqueueSnackbar("Succeeded to delete version.", { variant: "success" });
      closeDeleteWFVersionDialog();
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Failed to delete version.", { variant: "error" });
    }
  };

  const renderDeleteWFVersionDialog = () => (
    <DeleteWFVersionDialog
      open={open}
      onClose={closeDeleteWFVersionDialog}
      handleDelete={handleDelete}
      isLoading={isLoading}
    />
  );

  return {
    renderDeleteWFVersionDialog,
    openDeleteWFVersionDialog,
  };
};
