import { Stack, Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { infoState } from "redux-modules/info";
import {
  useCreateSubmittingFileWorkflowMutation,
  useGetWorkflowListQuery,
  useUpdateWorkflowStatusMutation,
} from "services/workflow";

import { WorkflowStatus } from "../../api/api";
import { defs, kOpts_FiscalYear } from "../../common/const_defs";
import { AdcTitle2, AdcTitle3 } from "../../components/atoms/AdcTitle";
import { NoneWrapper } from "../../components/atoms/NoneWrapper";
import { CommonSelect } from "../../components/molecular/CommonSelect";
import { useDlgMailSender } from "../../hooks/DialogContext";
import { kMD_LimitFull } from "../../mock/mock_data";
import { useDeleteWFVersionDialog } from "../../render-hooks/useDeleteWFVersionDialog";
import { FileAttachmentDialog } from "../file/FileAttachmentDialog";
import { FileDownloadDialog } from "../file/FileDownloadDialog";

import { WfMgmtList } from "./components/WorkflowListPanel";
import { FixDataDialog } from "./FixDataDialog";
import { NewReportDialog } from "./NewReportDialog";
import { NewWorkflowDialog } from "./NewWorkflowDialog";
import { OpenWorkflowDialog } from "./OpenWorkflowDialog";
import { WorkflowStatusDialog } from "./WorkflowStatusDialog";

import type {
  WorkflowAddData,
  FixDataInfo,
  SelectNode,
  JobWorkflow,
  WorkflowDelVerData,
  WorkflowStatusData,
  WorkflowMgmtData,
} from "../../common/type_defs";

const WfManagementPage = () => {
  const [fiscalYear, setFiscalYear] = useState<string>(kOpts_FiscalYear[0].value);
  const [limit] = useState<Record<string, boolean>>(kMD_LimitFull);
  const [params, setParams] = useState<Record<string, string>>({});
  const { openMailSender } = useDlgMailSender();
  const [isDlgOpenNewWF, setDlgOpenNewWF] = useState<boolean>(false);
  const [isDlgOpenNewRpt, setDlgOpenNewRpt] = useState<boolean>(false);
  const [isDlgOpenFileAtt, setDlgOpenFileAtt] = useState<boolean>(false);
  const [isDlgOpenFileDnld, setDlgOpenFileDnld] = useState<boolean>(false);
  const [isDlgOpenWorkflow, setDlgOpenWorkflow] = useState<boolean>(false);
  const [isDlgOpenWfStatus, setDlgOpenWfStatus] = useState<boolean>(false);
  const [isFixDataDlgOpen, setFixDataDlgOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { role } = useSelector(infoState);

  const { data: workflowList, refetch: refetchWorkflow } = useGetWorkflowListQuery({
    fiscalYear: parseInt(fiscalYear, 10),
  });
  const [createSubmittingFileWorkflow] = useCreateSubmittingFileWorkflowMutation();
  // const [createFixWorkflowVersion] = useCreateFixWorkflowVersionMutation();
  // const [deleteFixWorkflowVersion] = useDeleteFixWorkflowVersionMutation();
  const [updateWorkflowStatus] = useUpdateWorkflowStatusMutation();

  const handleFiscalYearChanged = (nv: SelectNode) => {
    setFiscalYear(nv.value);
  };

  const { renderDeleteWFVersionDialog, openDeleteWFVersionDialog } = useDeleteWFVersionDialog({
    refetchWorkflow,
  });

  const handleAction = async (act: string, vd: unknown) => {
    if (act === "new-workflow") {
      // navigate('/new-request');
      setDlgOpenNewWF(true);
    } else if (act === "new-workflow-cancel") {
      setDlgOpenNewWF(false);
    } else if (act === defs.kAct_ExecCreateWorkflow) {
      const wfData: WorkflowAddData = vd as WorkflowAddData;
      setDlgOpenNewWF(false);
      try {
        await createSubmittingFileWorkflow(wfData).unwrap();
        enqueueSnackbar(`Succeeded to create workflow.`, { variant: "success" });
      } catch (error) {
        enqueueSnackbar(`Failed to create workflow.`, { variant: "error" });
      }
    } else if (act === "new-report") {
      setDlgOpenNewRpt(true);
    } else if (act === "new-report-cancel") {
      setDlgOpenNewRpt(false);
    } else if (act === "new-report-create") {
      setDlgOpenNewRpt(false);
    } else if (act === "file-attachment-open") {
      setDlgOpenFileAtt(true);
    } else if (act === "file-attachment-create") {
      setDlgOpenFileAtt(false);
    } else if (act === "file-attachment-cancel") {
      setDlgOpenFileAtt(false);
    } else if (act === "file-download") {
      setDlgOpenFileDnld(true);
    } else if (act === "file-download-cancel") {
      setDlgOpenFileDnld(false);
    } else if (act === "file-download-create") {
      setDlgOpenFileDnld(false);
    } else if (act === "fix-data-open") {
      setFixDataDlgOpen(true);
    } else if (act === "fix-data-cancel") {
      setFixDataDlgOpen(false);
    } else if (act === "fix-data-create") {
      setFixDataDlgOpen(false);
    } else if (act === "open-workflow-open") {
      const wf = vd as JobWorkflow;
      setParams({
        wfType: wf.workflowType as string,
        fiscalYear,
        version: wf.version as string,
      });
      setDlgOpenWorkflow(true);
    } else if (act === "open-workflow-cancel") {
      setDlgOpenWorkflow(false);
    } else if (act === defs.kAct_ExecOpenWorkflow) {
      setDlgOpenWorkflow(false);
      const wsd: WorkflowStatusData = {
        workflowType: params.wfType,
        fiscalYear: params.fiscalYear,
        version: params.version,
        workflowStatus: WorkflowStatus.Open,
      };
      try {
        await updateWorkflowStatus(wsd).unwrap();
        enqueueSnackbar(`Succeeded to open workflow.`, { variant: "success" });
      } catch (error) {
        enqueueSnackbar(`Failed to open workflow.`, { variant: "error" });
      }
    } else if (act === defs.kAct_ExecCloseWorkflow) {
      const wf = vd as JobWorkflow;
      const wsd: WorkflowStatusData = {
        workflowType: wf.workflowType as string,
        fiscalYear,
        version: wf.version as string,
        workflowStatus: WorkflowStatus.Close,
      };
      try {
        await updateWorkflowStatus(wsd).unwrap();
        enqueueSnackbar(`Succeeded to close workflow.`, { variant: "success" });
      } catch (error) {
        enqueueSnackbar(`Failed to close workflow.`, { variant: "error" });
      }
    } else if (act === defs.kAct_ExecSendMail) {
      // TODO: refetch する必要ないのでは
      refetchWorkflow();
    } else if (act === "wf-mgmt-detail") {
      const wfmd = vd as WorkflowMgmtData;
      // navigate(`/wf-status?wfType=${wf.status}&fiscalYear=${fiscalYear}&version=1`);
      setParams({
        wfType: wfmd.workflowType ?? "",
        fiscalYear,
        version: wfmd.version ?? "",
      });
      setDlgOpenWfStatus(true);
    } else if (act === "wf-mgmt-notify") {
      const wfmd = vd as WorkflowMgmtData;
      const params = {
        workflowStatus: WorkflowStatus.Close,
        workflowType: wfmd.workflowType,
        fiscalYear,
      };
      openMailSender(params, handleAction);
    } else if (act === "cell-report") {
      navigate("/wf-status");
    } else if (act === "file-delete") {
      alert(act);
    } else if (act === "workflow-reopen") {
      const wfmd = vd as WorkflowMgmtData;
      const params: WorkflowStatusData = {
        workflowType: wfmd.workflowType ?? "",
        fiscalYear,
        version: wfmd.version ?? "",
        workflowStatus: WorkflowStatus.Close,
      };
      try {
        await updateWorkflowStatus(params).unwrap();
        enqueueSnackbar(`Succeeded to reopen workflow.`, { variant: "success" });
      } catch (error) {
        enqueueSnackbar(`Failed to reopen workflow.`, { variant: "error" });
      }
    } else if (act === defs.kAct_ExecFixVerCreate) {
      const info: FixDataInfo = vd as FixDataInfo;
      // try {
      //   await createFixWorkflowVersion(info).unwrap();
      //   enqueueSnackbar("Succeeded to create version.", { variant: "success" });
      // } catch (error) {
      //   if (isTimeoutError(error)) {
      //     enqueueSnackbar("Please wait for email", { variant: "success" });
      //   } else {
      //     enqueueSnackbar("Failed to create version.", { variant: "error" });
      //   }
      // }
      setFixDataDlgOpen(false);
    } else if (act === defs.kAct_ExecFixVerDelete) {
      const wfmd = vd as WorkflowMgmtData;
      const data: WorkflowDelVerData = {
        workflowType: wfmd.workflowType ?? "",
        fiscalYear,
        version: wfmd.version ?? "",
      };
      openDeleteWFVersionDialog(data);
    } else {
      console.log(act);
    }
    refetchWorkflow();
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12} container flexDirection={{ xs: "column", sm: "row" }} sx={{ mx: 0, mb: 1 }}>
          <AdcTitle2>Workflow List</AdcTitle2>
        </Grid>
        <Grid
          xs={12}
          container
          flexDirection={{ xs: "column", sm: "row" }}
          sx={{ ml: 2, mb: "2px" }}
        >
          <Stack
            direction="row"
            sx={{
              width: "100%",
              mr: 1,
              gap: "1rem",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <AdcTitle3>Fiscal Year:</AdcTitle3>
            <CommonSelect
              value={fiscalYear}
              options={kOpts_FiscalYear}
              onChanged={handleFiscalYearChanged}
              sxx={{ mr: 2 }}
            />
            <Box sx={{ flex: 1 }} />
            <NoneWrapper show={limit.new_request}>
              <Button
                variant="outlined"
                onClick={() => handleAction("new-workflow", null)}
                sx={{ minWidth: 120, mr: 2 }}
                disabled={!role?.hasWorkflowManagement}
              >
                Add Workflow
              </Button>
            </NoneWrapper>
          </Stack>
        </Grid>
        <Grid xs={12}>
          <WfMgmtList
            data={(workflowList as any) ?? []}
            onAction={handleAction}
            maxHeight={800}
            isLoading={false}
          />
        </Grid>
      </Grid>
      <NewWorkflowDialog
        open={isDlgOpenNewWF}
        onClose={() => setDlgOpenNewWF(false)}
        onAction={handleAction}
      />
      <NewReportDialog
        open={isDlgOpenNewRpt}
        onClose={() => setDlgOpenNewRpt(false)}
        onAction={handleAction}
      />
      <FileAttachmentDialog
        open={isDlgOpenFileAtt}
        onClose={() => setDlgOpenFileAtt(false)}
        onAction={handleAction}
      />
      <FileDownloadDialog
        open={isDlgOpenFileDnld}
        onClose={() => setDlgOpenFileDnld(false)}
        onAction={handleAction}
      />
      <FixDataDialog
        open={isFixDataDlgOpen}
        fiscalYear={parseInt(fiscalYear)}
        onClose={() => setFixDataDlgOpen(false)}
        onAction={handleAction}
      />
      <OpenWorkflowDialog
        open={isDlgOpenWorkflow}
        wfType={params.wfType}
        fiscalYear={params.fiscalYear}
        version={params.version}
        onClose={() => setDlgOpenWorkflow(false)}
        onAction={handleAction}
      />
      <WorkflowStatusDialog
        open={isDlgOpenWfStatus}
        wfType={params.wfType}
        fiscalYear={params.fiscalYear}
        version={params.version}
        onClose={() => setDlgOpenWfStatus(false)}
        onAction={handleAction}
      />
      {renderDeleteWFVersionDialog()}
    </>
  );
};

export default WfManagementPage;
