import { LoadingButton } from "@mui/lab";
import { Box, Button, Paper, Stack, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { MaterialReactTable } from "material-react-table";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";
import { useSelectCEDialog } from "render-hooks/useSelectCEDialog";
import {
  isApiFailedError,
  isFetchBaseQueryError,
  isInitiateJobRequest,
  isTimeoutError,
} from "services/common/type-guard";
import { usePrepareAndDownloadFileMutation, usePrepareFileMutation } from "services/file";
import { useAbortJobMutation, useInitiateJobMutation, useLazyGetJobQuery } from "services/job";
import { useLazyGetPEInfoQuery } from "services/pe";
import { useGetWorkflowFileTypeQuery } from "services/workflow";

import {
  JobAttributeEnum,
  JobDetailFileTypeEnum,
  JobDetailNextActionEnum,
  JobType,
  PEStatus,
  WorkflowType,
} from "../../../api/api";
import { defs } from "../../../common/const_defs";
import { AdcTitle1, AdcTitle2 } from "../../../components/atoms/AdcTitle";
import { NoneWrapper } from "../../../components/atoms/NoneWrapper";
import { CommonSelect } from "../../../components/molecular/CommonSelect";

import type { Job, JobDetail, PE } from "../../../api/api";
import type { SelectNode } from "../../../common/type_defs";
import type { RowSelectionState, Updater } from "@tanstack/react-table";
import type { MRT_ColumnDef, MRT_Row } from "material-react-table";
import type { ChangeEvent, FC } from "react";

const isCESelectNotRequired = (job: Job): boolean =>
  job.workflowStep === "DONE" ||
  job.workflowType === WorkflowType.NewReport ||
  job.workflowType === WorkflowType.Tango ||
  (job.workflowType === WorkflowType.TopSide && job.workflowStep === "INPUT") ||
  (job.workflowType?.startsWith("SUBMITTING_FILE") === true && job.workflowStep === "INPUT");

const getColumnDef = (
  onAction: (verb: string, detail: unknown) => void
): MRT_ColumnDef<JobDetail>[] => [
  {
    accessorKey: "companyName",
    header: "Company",
    size: 80,
    Cell: ({ row }) => (
      <div style={{ whiteSpace: "normal", wordBreak: "break-all" }}>{row.original.companyName}</div>
    ),
  },
  {
    accessorKey: "country",
    header: "Country",
    size: 120,
  },
  {
    accessorKey: "PECode",
    header: "CE Code",
    size: 120,
  },
  {
    accessorKey: "companyCategory",
    header: "Company Category",
    size: 160,
  },
  {
    accessorKey: "PECode",
    header: "Action",
    size: 160,
    Cell: ({ cell, row }) => (
      <Button
        size="small"
        color="primary"
        variant="text"
        aria-label="error information"
        onClick={() => onAction(defs.kAct_ViewPeStatus, row.original.PECode)}
        sx={{ m: 0, p: 0, minWidth: 50, width: 30 }}
      >
        Detail
      </Button>
    ),
  },
];
type JobDetailListProps = {
  isLoading: boolean;
  companies: PE[];
  job: Job;
  onAction: (act: string, vd: unknown) => void;
  onSelChanged: (sel: RowSelectionState) => void;
};

const JobDetailList: FC<JobDetailListProps> = ({
  companies,
  onAction,
  onSelChanged,
  isLoading,
  job,
}) => {
  const columns = useMemo<MRT_ColumnDef<JobDetail>[]>(() => getColumnDef(onAction), [onAction]);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  // const getRowSelProps = (props: { table: MRT_TableInstance<PE>; row: MRT_Row<JobDetail>; }): CheckboxProps => {
  //   // if (props.row.original.job_status === defs.kJobStat_InProgress) {
  //   //   return { disabled: true }
  //   // } else {
  //   return {}
  //   // }
  // }

  const handleRowSelChanged = useCallback(
    (rowSel: Updater<RowSelectionState>) => {
      const newSel = rowSel instanceof Function ? rowSel(rowSelection) : rowSel;
      setRowSelection(newSel);
      onSelChanged(newSel);
    },
    [onSelChanged, rowSelection]
  );
  return (
    <Paper elevation={0} sx={{}}>
      <MaterialReactTable
        columns={columns}
        data={companies}
        initialState={{ density: "compact" }}
        enableColumnActions={false}
        enableColumnFilters={false}
        enablePagination={false}
        enableRowSelection={!isCESelectNotRequired(job)}
        getRowId={(rd, index: number, parentRow: MRT_Row<JobDetail>) => rd.PECode ?? ""}
        onRowSelectionChange={handleRowSelChanged}
        enableSorting
        enableBottomToolbar={false}
        enableTopToolbar={false}
        enableRowVirtualization
        state={{ isLoading, rowSelection }}
        muiTableContainerProps={{ sx: { height: 300 } }}
        muiTableBodyRowProps={{ hover: true }}
      />
    </Paper>
  );
};

const isSelectedBeyondGroupCode = (ceList: string[]): boolean => {
  const splitCEList = ceList.map((ce) => ce.split("-"));
  if (splitCEList.some((ce) => ce.length !== 3)) {
    return false;
  }

  const groups = splitCEList.map(([groupCode]) => groupCode);

  if (groups.every((group) => group === groups.at(0))) {
    return true;
  }
  return false;
};

type JobActionInitProps = {
  job: Job;
  setJob: (job: Job) => void;
  onAction: (act: string, data: unknown) => void;
};

const JobActionInitPanel: FC<JobActionInitProps> = ({ job, setJob, onAction }) => {
  const [selList, setSelList] = useState<RowSelectionState>({});
  const [fileType, setFileType] = useState<SelectNode>({ label: "", value: job.fileType ?? "" });
  const { enqueueSnackbar } = useSnackbar();
  const { openSelectCEDialog, renderSelectCEDialog } = useSelectCEDialog();

  const { data: workflowFileType } = useGetWorkflowFileTypeQuery(
    {
      workflowStep: job.workflowStep ?? "",
      workflowType: job.workflowType ?? "",
    },
    { skip: job.workflowType !== "STANDARD_TEMPLATE" }
  );
  const [abortJob, { isLoading: isAbortJobLoading }] = useAbortJobMutation();
  const [getJob] = useLazyGetJobQuery();
  const [initiateJob, { isSuccess: isInitiateJobSuccess, isLoading: isInitiateJobLoading }] =
    useInitiateJobMutation();
  const [getPEInfo] = useLazyGetPEInfoQuery();
  const [prepareFile, { isLoading: isPrepareFileLoading }] = usePrepareFileMutation();
  const [prepareAndDownloadFile] = usePrepareAndDownloadFileMutation();
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [isLoadingSubmitFileDownload, setIsLoadingSubmitFileDownload] = useState(false);

  const fileTypeSelectionList: SelectNode[] = useMemo(
    () =>
      workflowFileType?.map((fileType) => ({
        label: fileType.displayName,
        value: fileType.name,
      })) ?? [],
    [workflowFileType]
  );

  const requestInitiateJob = useCallback(
    async (peList: string[]) => {
      const details = job.JobDetails!.filter((item) => peList.includes(item.PECode));
      const initiateData = {
        name: job.name,
        workflowType: job.workflowType,
        workflowStep: job.workflowStep,
        attribute: job.attribute,
        JobDetails: details,
        fiscalYear: job.fiscalYear,
        fileType: fileType.value,
        fixVersion: job.fixVersion,
      };

      if (!isInitiateJobRequest(initiateData)) {
        enqueueSnackbar(`Cannot initiate. Check each field.`, { variant: "error" });
        return;
      }

      try {
        const initiateJobResponse = await initiateJob(initiateData).unwrap();
        setJob(initiateJobResponse.job);
        enqueueSnackbar(initiateJobResponse.message, { variant: "success" });
      } catch (error) {
        enqueueSnackbar(`error when job initiate!`, { variant: "error" });
      }
    },
    [
      enqueueSnackbar,
      fileType.value,
      initiateJob,
      job.JobDetails,
      job.attribute,
      job.fiscalYear,
      job.fixVersion,
      job.name,
      job.workflowStep,
      job.workflowType,
      setJob,
    ]
  );

  const checkDisableSelectCE = useCallback((): boolean => {
    if (job.attribute === JobAttributeEnum.Approve) {
      return false;
    }
    if (
      job.workflowType !== "STANDARD_TEMPLATE" ||
      job.workflowType?.startsWith("SUBMITTING_FILE_")
    ) {
      return true;
    }
    if (job.type === JobType.NotStarted) {
      return false;
    }

    return true;
  }, [job.attribute, job.type, job.workflowType]);

  const isValid_JobName = useCallback(
    (jobName: string): boolean => jobName.indexOf(" ") === -1 && jobName.indexOf("　") === -1,
    []
  );

  const handleJobNameChanged = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (job.type !== JobType.InProgress) {
        let name = e.target.value;
        if (!isValid_JobName(name)) {
          enqueueSnackbar(`Space cannot be allowed`, { variant: "error" });
          name = name.replaceAll(" ", "");
          name = name.replaceAll("　", "");
          setJob({ ...job, name });
        } else {
          setJob({ ...job, name });
        }
        // onAction("job-changed", { ...job, name });
      }
    },
    [enqueueSnackbar, isValid_JobName, job, setJob]
  );
  // const handlePESelected = (aryPE: string[]) => {
  //   setJobData({ ...jobData, PEList: aryPE });
  // }
  const handleFileTypeChanged = useCallback((v: SelectNode) => {
    setFileType(v);
  }, []);

  const handleAction = useCallback(
    (act: string, data: unknown) => {
      if (act === defs.kAct_ViewPeStatus) {
        onAction(act, data);
      } else {
        // onAction('download', jobsSelected);
      }
    },
    [onAction]
  );

  const getFiscalYear = useCallback((date: Date): number => {
    if (date.getMonth() >= 4) {
      return date.getFullYear();
    }
    return date.getFullYear() - 1;
  }, []);

  const handleClickFilter = useCallback(async () => {
    const aryPE = await openSelectCEDialog({
      options: {
        workflowType: job.workflowType,
        workflowStep: job.workflowStep,
        fiscalYear: job.fiscalYear,
        fixVersion: job.fixVersion,
        jobStatus: job.attribute === JobAttributeEnum.Submit ? "INPUT_WAITING" : "APPROVE_WAITING",
      },
    });

    let ary: PE[] = [];
    try {
      const getPEInfoResponse = await getPEInfo({
        PECodeList: aryPE,
        fiscalYear: job.fiscalYear!,
      }).unwrap();
      ary = getPEInfoResponse.PEList.filter((pe) => aryPE.includes(pe.PECode));
    } catch (e) {}
    const aryDetail: JobDetail[] = ary.map((pe: PE) => {
      const detail: JobDetail = {
        PECode: pe.PECode,
        companyName: pe.companyName,
        fileType: JobDetailFileTypeEnum.SummarySheet,
        fileName: `file-${pe.PECode}`,
        nextAction: JobDetailNextActionEnum.ProceedNextStep,
        PEStatus: PEStatus.NotStarted,
        completedTime: "2023-07-23",
      };

      return detail;
    });
    setJob({ ...job, JobDetails: aryDetail });
  }, [getPEInfo, job, openSelectCEDialog, setJob]);

  const getSelectedItem = useCallback((): string[] => {
    const peList: string[] = [];
    for (const key in selList) {
      if (key in selList && selList[key]) {
        peList.push(key);
      }
    }
    return peList;
  }, [selList]);

  const handleClickPreset = useCallback(async () => {
    if (job.name === undefined || job.name === "") {
      enqueueSnackbar(`name is required!`, { variant: "error" });
      return;
    }
    const peList = getSelectedItem();
    const { fiscalYear } = job;

    if (!isSelectedBeyondGroupCode(peList)) {
      enqueueSnackbar("Preset across group codes is not allowed", { variant: "error" });
      return;
    }

    try {
      await prepareFile({
        fiscalYear,
        fixVersion: job.fixVersion,
        workflowType: job.workflowType,
        workflowStep: job.workflowStep,
        fileType: fileType.value,
        CECodeList: peList,
        name: job.name,
        presetType: "NEW",
      }).unwrap();
      enqueueSnackbar(`Succeeded to prepare file.`, { variant: "success" });
    } catch (error) {
      if (isApiFailedError(error)) {
        enqueueSnackbar(error.data.message, { variant: "error" });
      } else {
        enqueueSnackbar("Failed to prepare file.", { variant: "error" });
      }
      return;
    }

    if (job.type === JobType.NotStarted) {
      await requestInitiateJob(peList);
    }
  }, [enqueueSnackbar, fileType.value, getSelectedItem, job, prepareFile, requestInitiateJob]);

  const handleClickSubmitFileDownload = useCallback(async () => {
    setIsLoadingSubmitFileDownload(true);
    let name = job.name ?? "";
    if (!isValid_JobName(name)) {
      enqueueSnackbar(`Space is not allowed`, { variant: "error" });
      name = name.replaceAll(" ", "");
      name = name.replaceAll("　", "");
      setJob({ ...job, name });
      setIsLoadingSubmitFileDownload(false);
      return;
    }
    if (!name) {
      enqueueSnackbar(`name is requried!`, { variant: "error" });
      setIsLoadingSubmitFileDownload(false);
      return;
    }
    const peList = getSelectedItem();
    const fileType = "SUBMITTING_FILE_JOB";
    const { fiscalYear } = job;

    try {
      await prepareAndDownloadFile({
        prepareRequest: {
          fileType,
          CECodeList: peList,
          fiscalYear,
          workflowType: job.workflowType,
        },
        generateDownloadURLRequest: {
          fileType,
          category: "WORKFLOW",
        },
      });
    } catch (error) {
      setIsLoadingSubmitFileDownload(false);
      return;
    }

    if (job.type === JobType.NotStarted) {
      await requestInitiateJob(peList);
    }
    setIsLoadingSubmitFileDownload(false);
  }, [
    enqueueSnackbar,
    getSelectedItem,
    isValid_JobName,
    job,
    prepareAndDownloadFile,
    requestInitiateJob,
    setJob,
  ]);

  const handleClickDownload = useCallback(async () => {
    setIsLoadingDownload(true);
    let name = job.name ?? "";
    if (!isValid_JobName(name)) {
      enqueueSnackbar(`Space is not allowed`, { variant: "error" });
      name = name.replaceAll(" ", "");
      name = name.replaceAll("　", "");
      setJob({ ...job, name });
      setIsLoadingDownload(false);
      return;
    }
    if (!name) {
      enqueueSnackbar(`name is requried!`, { variant: "error" });
      setIsLoadingDownload(false);
      return;
    }
    const peList = getSelectedItem();
    const { fiscalYear } = job;

    try {
      await prepareAndDownloadFile({
        prepareRequest: {
          fiscalYear,
          fixVersion: job.fixVersion,
          workflowType: job.workflowType,
          workflowStep: job.workflowStep,
          name,
          fileType: fileType.value,
          CECodeList: peList,
        },
        generateDownloadURLRequest: {
          fileType: fileType.value,
          category: "WORKFLOW",
        },
      });
    } catch (error) {
      setIsLoadingDownload(false);
      return;
    }

    if (job.type === JobType.NotStarted) {
      await requestInitiateJob(peList);
    }
    setIsLoadingDownload(false);
  }, [
    enqueueSnackbar,
    fileType.value,
    getSelectedItem,
    isValid_JobName,
    job,
    prepareAndDownloadFile,
    requestInitiateJob,
    setJob,
  ]);

  const handleClickAbort = useCallback(async () => {
    let peList = getSelectedItem();
    if (isCESelectNotRequired(job)) {
      // CE選択が不要のJobは、Job単位でのAbortを行う
      peList = job.JobDetails?.map(({ PECode }) => PECode) ?? [];
    }
    if (peList.length === 0) {
      enqueueSnackbar(`please select CE`, { variant: "error" });
      return;
    }

    try {
      await abortJob({ jobId: job.id, PECode: peList }).unwrap();
      enqueueSnackbar(`job abort success!`, { variant: "success" });
    } catch (error) {
      if (isTimeoutError(error)) {
        // 本来は「504ステータスが返ってきたら」という条件にしたいが、ステータスコードが取得できないためクライアント側のタイムアウトで処理する。
        enqueueSnackbar(
          "Your request has been received, but processing may take some time. Please check back later.",
          { variant: "success" }
        );
      } else {
        enqueueSnackbar(`error when job abort!`, { variant: "error" });
      }
      return;
    }

    if (!isCESelectNotRequired(job)) {
      try {
        const jobData = await getJob({
          jobId: job.id,
        }).unwrap();
        setJob(jobData);
      } catch (error) {
        if (isFetchBaseQueryError(error) && error.status === 404) {
          setJob({
            ...job,
            JobDetails: [],
          });
          return;
        }
        enqueueSnackbar("Failed to retrieve job data. Please close the dialog and try again.", {
          variant: "error",
        });
      }
    }
  }, [abortJob, enqueueSnackbar, getJob, getSelectedItem, job, setJob]);

  const handleSelChanged = useCallback(
    (sel: RowSelectionState) => {
      setSelList(sel);
      setJob({
        ...job,
        selList: sel,
      });
    },
    [job, setJob]
  );

  return (
    <Paper elevation={0} sx={{ p: 2 }}>
      <Grid
        container
        flexDirection={{ xs: "column", sm: "row" }}
        sx={{ px: 1, fontSize: "12px" }}
        spacing={1}
      >
        <Grid xs={12} sm={12} sx={{ mb: 1 }}>
          <AdcTitle1 component="span">Task Management</AdcTitle1>
          <Typography component="span" sx={{ mx: 1, fontSize: "15px", color: "#a0a0a0" }}>
            - {job.workflowType ?? ""}
          </Typography>
        </Grid>
        <Grid xs={12} sm={12} sx={{ mt: 0 }}>
          <AdcTitle2 component="span">■ Job Name</AdcTitle2>
          <Typography component="span" sx={{ mx: 1, fontSize: "15px", color: "#a0a0a0" }}>
            - mandatory_field
          </Typography>
        </Grid>
        <Grid xs={12} sm={12} sx={{ pl: 3 }}>
          <TextField
            key="wf_name"
            name="wf_name"
            value={job.name}
            onChange={handleJobNameChanged}
            size="small"
            fullWidth
            disabled={job.type === JobType.InProgress}
            sx={{ maxWidth: "360px" }}
          />
        </Grid>
        <Grid xs={12} sm={12} sx={{ mt: 1 }}>
          <AdcTitle2 component="span">■ Target CE</AdcTitle2>
        </Grid>
        <Grid xs={12} sm={12} sx={{ pl: 3 }}>
          <Stack
            direction="row"
            sx={{ width: "100%", gap: "1.5rem", justifyContent: "flex-start" }}
          >
            <NoneWrapper show={job.workflowType === WorkflowType.StandardTemplate}>
              <CommonSelect
                isDisabled={
                  (job.fileType !== undefined && job.fileType !== "") || isInitiateJobSuccess
                }
                value={fileType}
                options={fileTypeSelectionList}
                onChanged={handleFileTypeChanged}
                sxx={{ minWidth: 200 }}
              />
            </NoneWrapper>
            <NoneWrapper show={job.workflowType === WorkflowType.StandardTemplate}>
              <LoadingButton
                variant="outlined"
                disabled={
                  !Object.keys(selList).length ||
                  // fileType?.value === "1CE_STANDARD_TEMPLATE" ||
                  job.type !== JobType.NotStarted
                }
                onClick={handleClickPreset}
                loading={isInitiateJobLoading || isPrepareFileLoading}
              >
                Preset
              </LoadingButton>
              <LoadingButton
                variant="outlined"
                disabled={!Object.keys(selList).length}
                onClick={handleClickDownload}
                loading={isLoadingDownload}
              >
                Download
              </LoadingButton>
            </NoneWrapper>
            <NoneWrapper
              show={
                (job.workflowType?.startsWith("SUBMITTING_FILE") ?? true) &&
                job.attribute === "APPROVE"
              }
            >
              <LoadingButton
                variant="outlined"
                disabled={!Object.keys(selList).length}
                onClick={handleClickSubmitFileDownload}
                loading={isLoadingSubmitFileDownload}
              >
                Download
              </LoadingButton>
            </NoneWrapper>
            {/* <NoneWrapper show={jobData.workflowType !== WorkflowType.StandardTemplate}><Button variant="outlined" disabled={jobData.type != "NOT_STARTED"} onClick={handleClickInitiate}>Initiate</Button></NoneWrapper> */}
            <LoadingButton
              variant="outlined"
              disabled={job.type !== "IN_PROGRESS"}
              onClick={handleClickAbort}
              loading={isAbortJobLoading}
            >
              Abort
            </LoadingButton>
            <Box sx={{ flex: 1 }} />
            <Button
              variant="outlined"
              disabled={checkDisableSelectCE()}
              onClick={handleClickFilter}
            >
              Select CE
            </Button>
          </Stack>
        </Grid>
        <Grid xs={12} sm={12} sx={{ pl: 3 }}>
          <JobDetailList
            companies={job.JobDetails ?? []}
            onAction={handleAction}
            onSelChanged={handleSelChanged}
            isLoading={false}
            job={job}
          />
        </Grid>
      </Grid>
      {renderSelectCEDialog()}
    </Paper>
  );
};

export default JobActionInitPanel;
export { JobActionInitPanel };
